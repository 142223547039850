var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"box"},[_c('v-card',{staticClass:"box"},[_c('v-card-title',[_c('div',{staticClass:"text-h4 pad seak_rubrik"},[_vm._v("Søg i bolighandler")])]),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-toolbar',[_c('v-tabs',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tabs-slider'),_c('v-tab',{staticClass:"primary--text",attrs:{"href":"#mobile-tabs-5-2"},on:{"click":_vm.mapClickedLast}},[_c('v-icon',[_vm._v("mdi-map-marker-radius-outline")]),_vm._v("Kort ")],1),_c('v-tab',{staticClass:"primary--text",attrs:{"href":"#mobile-tabs-5-3"},on:{"click":_vm.listClickedLast}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")]),_vm._v("Oversigt ")],1),_c('v-tab',{staticClass:"primary--text d-none",attrs:{"href":"#mobile-tabs-5-5"}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")]),_vm._v("story ")],1)],1)],1),_c('v-row',{staticClass:"p-8 mt-4"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{staticClass:"zindex",attrs:{"append-icon":"mdi-city","items":_vm.areas,"item-text":"kommune","item-value":"kode","label":"Vælg område","required":""},model:{value:(_vm.selectedArea),callback:function ($$v) {_vm.selectedArea=$$v},expression:"selectedArea"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-menu',{ref:"dateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","reactive":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Huse solgt siden:","append-icon":"mdi-calendar-range","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateMenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dateMenu.save(_vm.date)}}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.minPrice,"item-text":"mindste","item-value":"value","label":"Mindstepris","append-icon":"mdi-cash"},model:{value:(_vm.minPriceSelected),callback:function ($$v) {_vm.minPriceSelected=$$v},expression:"minPriceSelected"}})],1)],1),_c('v-row',[_c('v-col',[(_vm.noResult)?_c('v-alert',{attrs:{"text":"","prominent":"","type":"error","outlined":"","icon":"mdi-cloud-alert","dismissible":""}},[_vm._v(" Søgningen gav intet resultat, prøv igen. ")]):_vm._e(),(_vm.houseResults > 0)?_c('v-subheader',{staticClass:"pl-0 mt-0"},[_vm._v(" "+_vm._s(_vm.resultText)+" ")]):_vm._e()],1)],1),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',{attrs:{"value":'mobile-tabs-5-3'}},[[_c('v-card',[_c('v-card-title',{staticClass:"mb-5"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filtrér","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.houses,"search":_vm.search,"footer-props":{
                    showFirstLastPage: true,
                    'items-per-page-text': 'Handler per side',
                    'items-per-page-all-text': 'Alle',
                  }},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.adresse",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.vejNavn + " " + item.husNr + ", ")+_vm._s(item.supplerendeBynavn != "" ? item.supplerendeBynavn : item.kommuneNavn)+" ")]}}])})],1)]],2),_c('v-tab-item',{attrs:{"value":'mobile-tabs-5-2'}},[_c('v-card',[_c('TheMap',{on:{"storyFromMap":_vm.storyFromMap}})],1)],1),_c('v-tab-item',{attrs:{"value":'mobile-tabs-5-5'}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('Story',{attrs:{"story":_vm.story}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-card',{staticClass:"mx-auto pb-5",attrs:{"max-width":"400","tile":""}},[_c('v-card-title',[_c('h4',{staticClass:"nobreak"},[_vm._v("Lignende boliger solgt i nærheden")])]),_vm._l((_vm.filterHouses.slice(0,10)),function(house){return _c('v-list-item',{key:house.id,attrs:{"three-line":""},on:{"click":function($event){return _vm.updateStory(house)}}},[_c('v-list-item-content',{class:{ listcontent: house != _vm.filterHouses.slice(0,10)[_vm.filterHouses.slice(0,10).length-1] }},[_c('v-list-item-title',{staticClass:"label"},[_vm._v(_vm._s(house.vejNavn)+" "+_vm._s(house.husNr)+" ")]),_c('v-list-item-subtitle',{staticClass:"right_text"},[_vm._v(" "+_vm._s(house.headline)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Overtagelsesdato: "+_vm._s(house.overtagelsesDato)+" ")])],1)],1)})],2)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }