<template>
    <v-app text-center class="size">
      <v-main text-center>
        <v-row>
          <v-col>
            <HelloWorld />
          </v-col>
        </v-row>
      </v-main>
    </v-app>
  
</template>

<script>
import HelloWorld from "./components/HelloWorld";

export default {
  name: "App",

  components: {
    HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>
<style scoped>
.size {
  max-width: 1230px;
  margin: auto;
}
</style>
