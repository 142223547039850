<template>
  <div id="leaf"></div>
</template>

<script>
import L from "leaflet";

export default {
  name: "Leaf",
  props: {
    marker: Object,
  },
  data() {
    return {
      map: null,
      markers: [],
      featuregroup: null,
    };
  },

  mounted: function () {
    this.initMap();
    this.initMarkers();
  },
  watch: {
    marker() {
      this.initMarkers();
    },
  },
  methods: {
    initMap() {
      let streetLink =
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        satLink = '<a href="http://www.esri.com/">Esri</a>';

      let streetUrl = "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
        streetAttrib = "&copy; " + streetLink,
        satUrl =
          "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        satAttrib = "&copy; " + satLink;

      let streetMap = L.tileLayer(streetUrl, {
          attribution: streetAttrib,
        }),
        satMap = L.tileLayer(satUrl, { attribution: satAttrib });

      this.map = L.map("leaf", {
        layers: [satMap], // only add one!
      }).setView([this.marker.latitude, this.marker.longitude], 17);

      var baseLayers = {
        "Vis vejkort": streetMap,
        "Vis satellit": satMap,
      };

      L.control
        .layers(baseLayers, null, { position: "topleft" })
        .addTo(this.map);
    },
    initMarkers() {
      if (this.featuregroup != null) {
        this.featuregroup.clearLayers();
        this.markers = [];
      }
      var latlng = [];
      let lat = this.marker.latitude;
      let lng = this.marker.longitude;
      this.markers.push(L.marker([lat, lng]));
      latlng.push([lat, lng]);
      this.featuregroup = L.featureGroup(this.markers).addTo(this.map);
      this.featuregroup.addTo(this.map);
      var bounds = new L.LatLngBounds(latlng);
      this.map.fitBounds(bounds, {
        maxZoom: 17,
        duration: 1,
      });
    },
    moveMarker() {},
  },
};
</script>
<style scoped>
#leaf {
  height: 350px; /* The height is 400 pixels */
  width: 100%; /* The width is the width of the web page */
  position: sticky;
  z-index: 4;
}
</style>
