<template>
  <v-container class="box">
    <v-card class="box">
      <v-card-title>
        <div class="text-h4 pad seak_rubrik">Søg i bolighandler</div>
      </v-card-title>
      <v-row>
        <v-col md="12">
          <v-toolbar>
            <v-tabs v-model="tabs">
              <v-tabs-slider></v-tabs-slider>

              <v-tab
                href="#mobile-tabs-5-2"
                class="primary--text"
                @click="mapClickedLast"
              >
                <v-icon>mdi-map-marker-radius-outline</v-icon>Kort
              </v-tab>

              <v-tab
                href="#mobile-tabs-5-3"
                class="primary--text"
                @click="listClickedLast"
              >
                <v-icon>mdi-format-list-bulleted-square</v-icon>Oversigt
              </v-tab>
              <v-tab href="#mobile-tabs-5-5" class="primary--text d-none">
                <v-icon>mdi-format-list-bulleted-square</v-icon>story
              </v-tab>
            </v-tabs>
          </v-toolbar>
          <v-row class="p-8 mt-4">
            <v-col cols="12" sm="4">
              <v-select
                append-icon="mdi-city"
                v-model="selectedArea"
                :items="areas"
                item-text="kommune"
                item-value="kode"
                label="Vælg område"
                required
                class="zindex"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                reactive
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Huse solgt siden:"
                    append-icon="mdi-calendar-range"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" type="month" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateMenu = false"
                    >Cancel</v-btn
                  >
                  <v-btn text color="primary" @click="$refs.dateMenu.save(date)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="minPriceSelected"
                :items="minPrice"
                item-text="mindste"
                item-value="value"
                label="Mindstepris"
                append-icon="mdi-cash"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-alert
                v-if="noResult"
                text
                prominent
                type="error"
                outlined
                icon="mdi-cloud-alert"
                dismissible
              >
                Søgningen gav intet resultat, prøv igen.
              </v-alert>
              <v-subheader v-if="houseResults > 0" class="pl-0 mt-0">
                {{ resultText }}
              </v-subheader>
            </v-col>
          </v-row>

          <v-tabs-items v-model="tabs">
            <v-tab-item :value="'mobile-tabs-5-3'">
              <template>
                <v-card>
                  <v-card-title class="mb-5">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Filtrér"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>

                  <v-data-table
                    class="mt-5"
                    :headers="headers"
                    :items="houses"
                    :search="search"
                    @click:row="handleClick"
                    :footer-props="{
                      showFirstLastPage: true,
                      'items-per-page-text': 'Handler per side',
                      'items-per-page-all-text': 'Alle',
                    }"
                  >
                    <template v-slot:item.adresse="{ item }">
                      {{ item.vejNavn + " " + item.husNr + ", "
                      }}{{
                        item.supplerendeBynavn != ""
                          ? item.supplerendeBynavn
                          : item.kommuneNavn
                      }}
                    </template>
                  </v-data-table>
                </v-card>
              </template>
            </v-tab-item>
            <v-tab-item :value="'mobile-tabs-5-2'">
              <v-card>
                <TheMap v-on:storyFromMap="storyFromMap" />
              </v-card>
            </v-tab-item>
            <v-tab-item :value="'mobile-tabs-5-5'">
              <v-row>
                <v-col cols="12" sm="8">
                  <Story v-bind:story="story" />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card  class="mx-auto pb-5" max-width="400" tile>
                    <v-card-title>
                      <h4 class="nobreak">Lignende boliger solgt i nærheden</h4>
                    </v-card-title>
                    <v-list-item  v-for="house in filterHouses.slice(0,10)" :key="house.id" three-line @click="updateStory(house)">
                      <v-list-item-content  v-bind:class="{ listcontent: house != filterHouses.slice(0,10)[filterHouses.slice(0,10).length-1] }">
                        <v-list-item-title class="label"
                          >{{ house.vejNavn }} {{ house.husNr }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="right_text">
                          {{house.headline}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          Overtagelsesdato: {{house.overtagelsesDato}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import TheMap from "./TheMap";
import Story from "./Story";

export default {
  components: {
    TheMap,
    Story,
  },
  name: "HelloWorld",

  data: () => ({
    tabs: null,
    lastClicked: "",
    multiResult: "resultater",
    singleResult: "resultat",
    story: "",
    resultText: "",
    minPriceSelected: 1000000,
    minPrice: [
      { mindste: "Alle priser", value: 0 },
      { mindste: "500.000", value: 500000 },
      { mindste: "1.000.000", value: 1000000 },
      { mindste: "1.500.000", value: 1500000 },
      { mindste: "2.000.000", value: 2000000 },
      { mindste: "2.500.000", value: 2500000 },
      { mindste: "3.000.000", value: 3000000 },
      { mindste: "3.500.000", value: 3500000 },
      { mindste: "4.000.000", value: 4000000 },
      { mindste: "4.500.000", value: 4500000 },
      { mindste: "5.000.000", value: 5000000 },
      { mindste: "5.500.000", value: 5500000 },
      { mindste: "6.000.000", value: 6000000 },
    ],
    date: "2020-06",
    dateMenu: false,
    datemodal: false,
    alletext: "alle",
    areas: [],
    selectedArea: "alle",
    noResult: false,
    houseResults: 0,
    text:
      "!wefawef wlakemfl kwaemfl kmwaelf kmwaelkfm lwakemf lkmwaeflk mwealkfm lkwaemf lkm",
    search: "",
    url:
      "https://journalistbot.dk/api/bolighandler/alle/2020-06/2500000",
    headers: [
      {
        text: "Adresse",
        align: "start",
        value: "adresse",
      },
      { text: "Handel", value: "headline" },
      { text: "Købspris", value: "iAltKoebeSum" },
      { text: "Ejendomsværdi", value: "ejendomsvaerdiSamlet" },
      { text: "Grundværdi", value: "grundVaerdiSamlet" },
      { text: "KVM", value: "beboelsesAreal" },
    ],
    houses: [],
  }),
  computed: {
    // a computed getter
    makeAddress: function () {
      // `this` points to the vm instance
      return this.message.split("").reverse().join("");
    },
    
   filterHouses: function () {
     //of the search results in houses, find the ones that a within 1 km of clicked house.
     
     let filteredHouses = []

     this.houses.forEach(house => {
       let dist = this.calculateDistance(this.story.latitude, this.story.longitude, house.latitude, house.longitude)
       if(dist <= 1 && dist > 0 ){
          filteredHouses.push(house)
       }
     });
     return filteredHouses;

     },
  },
  mounted: async function () {
    let url = "https://journalistbot.dk/api/koder";
    let response = await fetch(url);
    this.areas.push({
      kode: "alle",
      kommune: "Alle",
    });

    let result = await response.json();

    result.forEach((area) => {
      this.areas.push(area);
    });
    this.getData();
  },
  
  watch: {
    minPriceSelected() {
      this.updateUrl();
      this.tabs = this.lastClicked;
    },
    selectedArea() {
      this.updateUrl();
      this.tabs = this.lastClicked;
    },
    date() {
      this.updateUrl();
      this.tabs = this.lastClicked;
    },
    houseResults() {
      if (this.houseResults == 1000) {
        this.resultText =
          "Din søgning gav for mange resultater. De seneste 1000 boligsalg er vist.";
      } else if (this.houseResults > 1) {
        this.resultText =
          "Din søgning gav " + this.houseResults + " resultater.";
      } else if (this.houseResults == 1) {
        this.resultText = "Din søgning gav " + this.houseResults + " resultat.";
      }
    },
  },
  methods: {
    updateUrl() {
      this.url =
        "https://journalistbot.dk/api/bolighandler/" +
        this.selectedArea +
        "/" +
        this.date +
        "/" +
        this.minPriceSelected.toString();
      this.getData();
    },
    mapClickedLast() {
      this.lastClicked = "mobile-tabs-5-2";
    },
    listClickedLast() {
      this.lastClicked = "mobile-tabs-5-3";
    },
    calculateDistance(lat1a,lng1a,lat2a,lng2a){
      let lat1 = this.toRadians(lat1a);
      let lat2 = this.toRadians(lat2a);
      let lng1 = this.toRadians(lng1a);
      let lng2 = this.toRadians(lng2a);

      let dlon = lng2-lng1;
      let dlat = lat2-lat1;
      let a = Math.pow(Math.sin(dlat / 2), 2) 
                 + Math.cos(lat1) * Math.cos(lat2) 
                 * Math.pow(Math.sin(dlon / 2),2);
      let c = 2 * Math.asin(Math.sqrt(a));
      
      let r = 6371;

      return (c*r)
    },
    toRadians(degrees){
      return degrees * (Math.PI/180);
    },
    async getData() {
      const url = this.url;
      const response = await fetch(url);
      const json = await response.json();
      if (Array.isArray(json) && json.length >= 1) {
        this.noResult = false;
        this.houseResults = json.length;
        this.houses = json;
        this.$root.$emit("newData", this.houses);
      } else {
        this.noResult = true;
        this.houseResults = 0;
      }
    },
    handleClick(row) {
      this.story = row;
      this.tabs = "mobile-tabs-5-5";
      this.lastClicked = "mobile-tabs-5-3";
    },
    storyFromMap(marker) {
      this.story = marker;
      this.tabs = "mobile-tabs-5-5";
      this.lastClicked = "mobile-tabs-5-2";
    },
    updateStory(house){
      this.story = house;
      this.tabs = "mobile-tabs-5-5";
    },
  },
};
</script>
<style scoped>
.noShadow{
  box-shadow: 0px 4px 0px 12px rgba(0, 0, 0, 0),
    16px 16px 16px 0px rgba(0, 0, 0, 0), 6px 4px 6px 0px rgba(0, 0, 0, 0);
}
.zindex {
  z-index: 8;
}
.listcontent{
  border-bottom: 1px solid lightgray;
}
.box {
  padding: 6px 20px 6px 20px;
}
.pad {
  padding: 6px 0px 6px 0px;
  white-space: pre-wrap;
  word-break: break-word;
}
h1,
h2,
h3,
h4,
h5 {
  display: block;
  color: #474340;
  margin-left: 0px;
  margin-right: 0px;

  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;

  line-height: 1.1;
}
.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 4px 0px 12px rgba(0, 0, 0, 0),
    16px 16px 16px 0px rgba(0, 0, 0, 0), 6px 4px 6px 0px rgba(0, 0, 0, 0);
}
.nobreak{
    white-space: pre-wrap;
    word-break: break-word;
}
.label {
  color: #1e88e5;
  display: inline-block;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}
.seak_rubrik{
  font-family: "Roboto Condensed", sans-serif impor !important;
  font-weight: 700;
}
.right_text{
  color: rgba(0, 0, 0, 1) !important;
  font-weight: 500;
}
</style>
