<template>
  <div id="map"></div>
</template>

<script>
import L from "leaflet";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";

export default {
  name: "TheMap",
  data() {
    return {
      map: null,
      datas: [],
      featuregroup: null,
      tileLayer: null,
      json: [],
      url: undefined,
      markers: [],
      center: [55.1, 10.3],
      mcg: null,
    };
  },

  mounted: function () {
    this.$root.$on("newData", (datas) => {
      this.datas = datas;
    });
    this.initMap();
  },
  watch: {
    datas(val) {
      this.initMarkers(val);
    },
  },
  methods: {
    initMap() {
      var tiles = L.tileLayer("//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18,
        attribution:
          '&copy; <a href="//openstreetmap.org/copyright">OpenStreetMap</a> Af Rune Heidtmann',
      });

      this.map = L.map("map", {
        center: L.latLng(this.center),
        zoom: 11,
        layers: [tiles],
      });
    },
    initMarkers() {
      if (this.mcg != null) {
        this.mcg.clearLayers();
      }

      this.mcg = L.markerClusterGroup({
        chunkedLoading: true,
        //singleMarkerMode: true,
        spiderfyOnMaxZoom: true,
      });

      let latlng = [];

      this.datas.forEach((marker) => {
        if (marker.latitude != null) {
          let lat = marker.latitude;
          let lng = marker.longitude;
          let marks = L.marker(new L.LatLng(lat, lng)).on("click", () => {
            this.sendStoryToParentDrawer(marker); //in this code, the output i is always the length of the array
          });
          this.mcg.addLayer(marks);
          latlng.push([lat, lng]);
        }
      });

      this.map.addLayer(this.mcg);
      let bounds = new L.LatLngBounds(latlng);
      this.map.fitBounds(bounds, {
        maxZoom: 14,
        duration: 1,
      });
    },
    sendStoryToParentDrawer(marker){
      this.$emit('storyFromMap',marker);
    },
  },
};
</script>
<style>
.mapRubrik {
  font-size: 22px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 0px;
}
.eventkategori {
  font-size: 14px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
</style>
<style scoped>
#map {
  height: 800px; /* The height is 400 pixels */
  width: 100%; /* The width is the width of the web page */
  position: sticky;
  z-index: 4;
}
</style>
