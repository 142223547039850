<template>
  <v-card elevation="0" class="mx-auto pl-3 pr-3 pt-0 pb-8" max-width="910">
    <div class="article__extra">
      <span class="label">{{ story.kommuneNavn }}</span>
      <span class="plus">
        <span class="plus_sign">&#43;</span> Kun for abonnenter</span
      >
    </div>
    <v-card-title class="title">
      <h1 class="article__lead">{{ story.headline }}</h1>
    </v-card-title>

    <Leaf v-bind:marker="story" />

    <v-card-subtitle class="pr-0 pl-0">
      {{ story.subtitle }}
    </v-card-subtitle>
    <v-card-text class="article__text pr-0 pl-0" v-html="story.content"> </v-card-text>
  </v-card>
</template>
<script>
import Leaf from "./Leaf";
export default {
  name: "Story",
  components: {
    Leaf,
  },
  props: {
    story: Object,
  },

  data() {
    return {};
  },
};
</script>
<style scoped>
h1,
h2,
h3,
h4,
h5 {
  display: block;
  color: #474340;
  margin-left: 0px;
  margin-right: 0px;

  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;

  line-height: 1.1;
}
.article__lead,
.article__subhead {
  white-space: pre-wrap;
  word-break: break-word;
  hyphens: auto;
}
.article__lead {
  color: #474340;
    white-space: pre-wrap;
  word-break: break-word;
}
.article__extra {
  margin-bottom: 12px;
}
.article__text {
  font-family: Lora, serif;
  color: #000000 !important;
  font-size: 16px;
  line-height: 1.625;
}
.label {
  color: #1e88e5;
  display: inline-block;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}
.title {
  padding: 0px;
  margin-bottom: 25px;
}
.plus {
  color: #736f6b;
  font-size: 13px;
  margin-left: 18px;
  line-height: 1;
  font-weight: 400;
}
.plus_sign {
  color: #1e88e5;
  display: inline-block;
  font-weight: 700;
}
</style>